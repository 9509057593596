import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { WikusSection } from '@wikus-ui/components/core/spacing';
import WikusPageLayout from '@wikus-ui/components/PageLayout';

import { RootState } from 'src/reducers';

import { CustomerStorageDashboard } from '../../components/customer/storage/dashboard';
import { CustomerUsers } from './../../components/customer/users/index';

import { Customer } from 'src/model/customer';

import * as CustomerActions from './../../actions/customer';
import { CustomerMachinesAdmin } from '../../components/customer/machines/admin';
import { CustomerMachinesDashboard } from '../../components/customer/machines/dashboard';
import { CustomerRouteParams } from './models';
import { CustomerSettings } from '../../components/customer/settings';
import { CustomerStorageAdmin } from '../../components/customer/storage/admin';
import { NavigationUrl } from '@wikus-ui/components/core/hero';
import { Redirect, Route, useParams } from 'react-router-dom';
import { RoleRoute } from '../../routes/role';
import { Routes } from '../../routes';
import { find } from 'lodash';
import { useActions } from '../../actions';
import { useUser } from '../../hooks/user';

export type TCustomerProps = {
  customer?: Customer;
};
const ChildRoutes = {
  storage: {
    base: Routes.customer + '/storage',
    dashboard: Routes.customer + '/storage/dashboard',
    admin: Routes.customer + '/storage/admin',
  },
  machines: {
    base: Routes.customer + '/machines',
    dashboard: Routes.customer + '/machines/dashboard',
    admin: Routes.customer + '/machines/admin',
  },
  settings: {
    base: Routes.customer + '/settings',
    users: Routes.customer + '/settings/users',
  },
};

export const CustomerPage = () => {
  const user = useUser();
  const intl = useIntl();
  const { id: customerId } = useParams<CustomerRouteParams>();

  const customerActions: typeof CustomerActions = useActions(CustomerActions);
  const customers = useSelector((state: RootState) => state.customers);
  const customer = find(customers, c => c.id === customerId);

  useEffect(() => {
    customerActions.fetchDetails(customerId);
  }, [])

  const navigationUrls: NavigationUrl[] = customer ? [
    user.role !== 'U' && {
      label: intl.formatMessage({ id: 'navigation.storage' }),
      url: user.role !== 'U' ? ChildRoutes.storage.base.replace(':id', customerId) : ChildRoutes.storage.admin.replace(':id', customerId),
      items: user.role !== 'U'
        ? [{
          label: intl.formatMessage({ id: 'navigation.storage.dashboard' }),
          url: ChildRoutes.storage.dashboard.replace(':id', customerId),
        }, {
          label: intl.formatMessage({ id: 'navigation.storage.admin' }),
          url: ChildRoutes.storage.admin.replace(':id', customerId),
        }]
        : undefined,
    },
    user.role !== 'U' && customer?.settings?.featureMachines && {
      label: intl.formatMessage({ id: 'navigation.machines' }),
      url: ChildRoutes.machines.base.replace(':id', customerId),
      items: [{
        label: intl.formatMessage({ id: 'navigation.machines.dashboard' }),
        url: ChildRoutes.machines.dashboard.replace(':id', customerId),
      }, {
        label: intl.formatMessage({ id: 'navigation.machines.admin' }),
        url: ChildRoutes.machines.admin.replace(':id', customerId),
      }],
    },
    user.role !== 'U' && {
      label: intl.formatMessage({ id: 'navigation.settings' }),
      url: ChildRoutes.settings.base.replace(':id', customerId),
      items: [{
        label: intl.formatMessage({ id: 'navigation.settings' }),
        url: ChildRoutes.settings.base.replace(':id', customerId),
      }, {
        label: intl.formatMessage({ id: 'navigation.settings.users' }),
        url: ChildRoutes.settings.users.replace(':id', customerId),
      }],
    }
  ].filter(r => r) as NavigationUrl[] : [];

  return (
    <WikusPageLayout
      userName={`${user.firstName} ${user.lastName}`}
      userRole={intl.formatMessage({ id: `roles.${user.role}` })}
      title={customer?.customerName}
      heroWithImage={true}
      navigationUrls={navigationUrls}
    >
      <WikusSection spacingDirection="down">
        <RoleRoute roles={['A', 'CA']}>
          <Route component={CustomerUsers} path={ChildRoutes.settings.users} />
          <Route component={CustomerSettings} path={ChildRoutes.settings.base} />
          <Route component={CustomerStorageAdmin} path={ChildRoutes.storage.admin} />
          <Route component={CustomerStorageDashboard} path={ChildRoutes.storage.dashboard} />
          <Route component={CustomerMachinesAdmin} path={ChildRoutes.machines.admin} />
          <Route component={CustomerMachinesDashboard} path={ChildRoutes.machines.dashboard} />
          <Redirect to={ChildRoutes.storage.dashboard} />
        </RoleRoute>
        <RoleRoute role="U">
          <Route component={CustomerStorageAdmin} path={ChildRoutes.storage.admin} />
          <Redirect to={ChildRoutes.storage.admin} />
        </RoleRoute>
      </WikusSection>
    </WikusPageLayout>
  );
};
