import { AppBar, Box, Grid, Toolbar } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { HeaderChevron } from './icons';
import { Link } from 'react-router-dom';
import { Logo } from './assets';
import { Theme, withStyles } from '@material-ui/core/styles';
import { WikusHeroMenuItem } from './hero-menu';
import { WikusHeroTypography, WikusTypography } from './typography';
import { WikusItem } from './spacing';
import { WikusUnstyledLink } from './link';
import headerBackgroundPng from '../../assets/img/header-background.png';

const styles: any = (theme: Theme) => {
  return {
    container: {
      height: '295px',
    },
    containerPlain: {
      height: '190px',
    },
    header: {
      clipPath: 'polygon(0 0, 0 100%, 100% 245px, 100% 0);',
      paddingBottom: '50px',
      marginBottom: '-50px',
    },
    headerPlain: {
      clipPath: 'polygon(0 0, 0 100%, 100% 140px, 100% 0);',
      paddingBottom: '50px',
      marginBottom: '-50px',
    },
    headerWithoutImage: {
      backgroundColor: theme.palette.grey[300],
    },
    headerWithImage: {
      backgroundImage: `url(${headerBackgroundPng});`,
      backgroundSize: 'cover',
      backgroundPositionY: 'center',
    },
    fontWithImage: {
      color: '#fff !important',
      textShadow: '0 0 5px #222 !important',
    },
    navBar: {
      backgroundColor: theme.palette?.background.paper,
      boxShadow: `0 0 20px 0 ${theme.palette?.grey?.[400]}`,
      borderRadius: '4px',
      padding: 0,
    },
    navBarWithImage: {
      backgroundColor: theme.palette?.background.paper,
      boxShadow: 'none',
      borderRadius: '4px',
      padding: 0,
    },
    link: {
      textDecoration: 'none',
    },
  };
};

export type NavigationUrl = {
  items?: NavigationUrl[];
  url: string;
  label: string;
}

const WikusHero = withStyles(styles)(
  ({
    classes,
    isPlain,
    withImage,
    title,
    subtitle,
    children,
    userName = null,
    userRole = null,
    onClickUser,
    canGoToHome,
    homeRoute = '/',
    logoutRoute,
    navigationUrls = [],
    ...props
  }: {
    [key: string]: any;
    navigationUrls?: NavigationUrl[];
  }) => {
    const fontClasses = withImage
      ? {
        root: classes.fontWithImage,
      }
      : {};
    const headerClassName = `${isPlain ? classes.headerPlain : classes.header
      } ${withImage ? classes.headerWithImage : classes.headerWithoutImage}`;
    const containerClassName = isPlain
      ? classes.containerPlain
      : classes.container;
    const navBarClasses = {
      root: withImage ? classes.navBarWithImage : classes.navBar,
    };

    return (
      <header className={headerClassName}>
        <Box mx={5}>
          <Grid container direction="column" className={containerClassName}>
            {!isPlain && (
              <Box mb={canGoToHome ? 1 : 0} mt={1}>
                <Grid container direction="row" justifyContent="space-between">
                  {!canGoToHome ? (
                    <WikusUnstyledLink to={homeRoute}>
                      <WikusTypography
                        style={{ display: 'inline-flex', alignItems: 'center' }}
                        classes={fontClasses}
                        variant="caption"
                        isBold={true}
                      >
                        <HeaderChevron />
                        <FormattedMessage id="hero.redirect-to-start" />
                      </WikusTypography>
                    </WikusUnstyledLink>
                  ) : (
                    <div></div>
                  )}

                  <Grid>
                    <WikusTypography
                      onClick={() => onClickUser?.()}
                      classes={fontClasses}
                      variant="caption"
                      isBold={true}
                      data-test="name"
                    >
                      {!!userName && !!userRole && `${userName} | ${userRole}`}
                    </WikusTypography>
                    {!!userName && (
                      <WikusUnstyledLink to={logoutRoute}>
                        <WikusTypography
                          style={{ marginLeft: 10 }}
                          classes={fontClasses}
                          variant="caption"
                          isBold={true}
                        >
                          <FormattedMessage id="logout" />
                        </WikusTypography>
                      </WikusUnstyledLink>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}

            {!isPlain && (
              <AppBar classes={navBarClasses} position="relative">
                <WikusItem spacingDirection="both">
                  <Toolbar disableGutters={true}>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Link to={homeRoute}>
                          <Logo></Logo>
                        </Link>
                      </Grid>
                      {navigationUrls.map((nav, i) => (
                        <WikusHeroMenuItem key={i} {...nav} />
                      ))}
                    </Grid>
                  </Toolbar>
                </WikusItem>
              </AppBar>
            )}

            <Grid item xs>
              <Grid style={{ height: '100%' }} container direction="row">
                <Grid style={{ height: '100%' }} item xs>
                  <Grid
                    style={{ height: '100%' }}
                    container
                    direction="column"
                    justifyContent="center"
                  >
                    <Box>
                      {title && (
                        <WikusHeroTypography classes={fontClasses}>
                          {title}
                        </WikusHeroTypography>
                      )}
                      {subtitle && (
                        <WikusTypography classes={fontClasses}>
                          {subtitle}
                        </WikusTypography>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Grid style={{ height: '100%' }} item>
                  <Grid
                    style={{ height: '100%' }}
                    container
                    direction="column"
                    justifyContent="center"
                  >
                    {children}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </header>
    );
  }
);
// set for storybook to render html preview correctly
WikusHero.displayName = 'WikusHero';

export { WikusHero };
