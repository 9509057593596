import * as MachineActions from '../../../../actions/customer-machine';
import { CreateMachineForm } from '../create-machine';
import { CustomerRouteParams } from '../../../../pages/customer/models';
import { Machine } from '../../../../model/customer-machine';
import { MachineCard } from '../machine-card';
import { RootState } from '../../../../reducers';
import { WikusButton } from '@wikus-ui/components/core/button';
import { WikusHr } from '@wikus-ui/components/core/hr';
import { WikusItem, WikusSection } from '@wikus-ui/components/core/spacing';
import { WikusPanel } from '@wikus-ui/components/core/card';
import { useActions } from '../../../../actions';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const CustomerMachinesAdmin = () => {
  const intl = useIntl();
  const { id: customerId } = useParams<CustomerRouteParams>();
  const machines = useSelector((state: RootState) => state.customerMachines);
  const customerMachineActions: typeof MachineActions =
    useActions(MachineActions);

  const [machineBeingEdited, setMachineBeingEdited] = useState<Machine | null>(
    null
  );

  useEffect(() => {
    if (customerId) {
      customerMachineActions.fetchMachines(customerId);
    }
  }, [customerId]);

  return (
    <>
      <CreateMachineForm />
      <WikusSection spacingDirection="up">
        <WikusPanel
          title={intl.formatMessage({ id: 'customers.tabs.machines' })}
        >
          {machines?.map((machine, index) => (
            <WikusItem spacingDirection="down" key={machine.id}>
              {index > 0 ? <WikusHr color="grey" /> : null}
              <MachineCard
                machine={machine}
                isExpanded={machineBeingEdited?.id === machine.id}
                onClose={() => setMachineBeingEdited(null)}
                buttons={
                  machineBeingEdited?.id !== machine.id
                    ? [
                      <WikusButton
                        color="primary"
                        onClick={() => setMachineBeingEdited(machine)}
                      >
                        {intl.formatMessage({ id: 'details' })}
                      </WikusButton>
                    ]
                    : []
                }
              >
              </MachineCard>
            </WikusItem>
          ))}
        </WikusPanel>
      </WikusSection>
    </>
  );
}