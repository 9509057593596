import { CreateMachineDto, MachineAction, MachineActions } from '../model/customer-machine';

export function fetchMachines(
  customerId: string
): MachineAction {
  return {
    type: MachineActions.FETCH_CUSTOMER_MACHINES,
    payload: {
      request: {
        url: `/machines/forcustomer/${customerId}`,
        method: 'GET',
      },
    },
  };
}

export function createMachine(
  customerId: string,
  dto: CreateMachineDto
): MachineAction {
  return {
    type: MachineActions.CREATE_CUSTOMER_MACHINE,
    payload: {
      request: {
        url: `/machines/forcustomer/${customerId}`,
        method: 'POST',
        data: dto,
      },
    },
  };
}

export function fetchMachinesStatisticsWithdrawals(
  customerId: string,
  options: {
    unitCount: number,
    year?: number,
    month?: number,
  },
  machineIds?: string[]
) {
  return {
    type: MachineActions.FETCH_CUSTOMER_MACHINES_STATISTICS_WITHDRAWAL,
    payload: {
      request: {
        url: `/machines/forcustomer/${customerId}/statistics/withdrawals`,
        method: 'GET',
        params: {
          ...options,
          unitCount: (!options.year && !options.month) ? options.unitCount : undefined,
          unit: (!options.year && !options.month) && (options.unitCount > 1) ? 'month' : 'day',
          machineIds: machineIds && JSON.stringify(machineIds),
        },
      },
    },
  };
}

export function fetchMachineStatisticsWithdrawals(
  customerId: string,
  machineId: string,
  options: {
    unitCount: number,
    year?: number,
    month?: number,
  }
) {
  return {
    type: MachineActions.FETCH_CUSTOMER_MACHINE_STATISTICS_WITHDRAWAL,
    payload: {
      request: {
        url: `/machines/forcustomer/${customerId}/${machineId}/statistics/withdrawals`,
        method: 'GET',
        params: {
          ...options,
          unitCount: (!options.year && !options.month) ? options.unitCount : undefined,
          unit: (!options.year && !options.month) && (options.unitCount > 1) ? 'month' : 'day',
        },
      },
    },
  };
}