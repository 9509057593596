import { ArticleEventCard } from '../storage/articleEventCard';
import { Grid } from '@material-ui/core';
import { Machine } from '../../../model/customer-machine';
import { TWikusModelCardProps, WikusModelCard } from '@wikus-ui/components/complex/model-card';
import { WikusAvatar } from '@wikus-ui/components/core/avatar';
import { WikusDetailRow } from '@wikus-ui/components/complex/detail-block';
import { WikusModal } from '@wikus-ui/components/core/modal';
import { WikusPanel } from '@wikus-ui/components/core/card';
import { getInitials } from '@wikus-ui/utils';
import { useIntl } from 'react-intl';
import { useUser } from '../../../hooks/user';
import React from 'react';

export type MachineCardProps = Omit<TWikusModelCardProps, 'avatar' | 'details' | 'title'> & {
  machine: Machine;
  children?: React.ReactNode;
  isExpanded?: boolean;
  onClose?: () => void;
}

export const MachineCard = ({
  machine,
  isExpanded,
  onClose,
  children,
  ...props
}: MachineCardProps) => {
  const intl = useIntl();
  const user = useUser();

  return (
    <>
      <WikusModelCard
        fullWidth
        elevation={0}
        avatar={
          <WikusAvatar src={machine.extra?.imageBase64 && `data:image/jpeg;base64,${machine.extra?.imageBase64}`} size="large">
            {getInitials(machine.name)}
          </WikusAvatar>
        }
        details={[
          {
            label: intl.formatMessage({
              id: 'customers.tabs.machines.description',
            }),
            value: machine.description,
          }
        ]}
        title={machine.name}
        {...props}
      >
      </WikusModelCard>
      <WikusModal
        title={machine.name}
        onClose={onClose}
        open={isExpanded}>
        <Grid container spacing={2}>
          <Grid item container xs={false} md={2}></Grid>
          {/* <Grid item container spacing={2} md={4} alignItems='stretch' direction='column'>
            <Grid item>
              <WikusPanel
                title={intl.formatMessage({ id: 'article.settings' })}
              >
                {React.Children.map(children, c => (<Grid item>
                  {c}
                </Grid>))}
              </WikusPanel>
            </Grid>
          </Grid> */}
          <Grid item container spacing={2} md={4}>
            <Grid item xs>
              <WikusPanel
                title={intl.formatMessage({ id: 'machine.specifications' })}
              >
                <WikusDetailRow variant='equal-start' items={[
                  {
                    label: intl.formatMessage({
                      id: 'customers.tabs.machines.description',
                    }),
                    value: machine.description,
                    isFullWidth: true,
                  },
                  {
                    label: intl.formatMessage({
                      id: 'customers.tabs.machines.width',
                    }),
                    value: machine.width,
                    isFullWidth: true,
                  },
                  {
                    label: intl.formatMessage({
                      id: 'customers.tabs.machines.thickness',
                    }),
                    value: machine.thickness,
                    isFullWidth: true,
                  }
                ]} />
              </WikusPanel>
            </Grid>
          </Grid>
          <Grid item container spacing={2} md={4}>
            <Grid item xs>
              <WikusPanel
                title={intl.formatMessage({ id: 'article.events' })}
              >
                {machine?.events?.map(event => (
                  <Grid item container justifyContent='flex-start'>
                    <ArticleEventCard
                      event={event} />
                  </Grid>
                ))}
              </WikusPanel>
            </Grid>
          </Grid>
        </Grid>
      </WikusModal>
      {/* {(isExpanded && !children) && (
        <Grid container justifyContent='center'>
          {article.events?.map(event => (
            <MachineEventCard
              event={event} />
          ))}
        </Grid>
      )} */}
    </>
  );
};
