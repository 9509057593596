import { RequestPayload, ResponsePayload } from './request';
import { StatisticTimeSeriesData } from './statistics';
import { StockArticleEvent } from './customer-article';

export interface MachineExtra {
  id: number;
  imageBase64?: string;
}

export interface Machine {
  id: string;
  name: string;
  description: string;
  width: number;
  thickness: number;
  extra?: MachineExtra;
  events?: StockArticleEvent[];
}

export enum MachineActions {
  FETCH_CUSTOMER_MACHINES = 'FETCH_CUSTOMER_MACHINES',
  FETCH_CUSTOMER_MACHINES_SUCCESS = 'FETCH_CUSTOMER_MACHINES_SUCCESS',
  FETCH_CUSTOMER_MACHINES_FAIL = 'FETCH_CUSTOMER_MACHINES_FAIL',

  FETCH_CUSTOMER_MACHINES_STATISTICS_WITHDRAWAL = 'FETCH_CUSTOMER_MACHINES_STATISTICS_WITHDRAWAL',
  FETCH_CUSTOMER_MACHINES_STATISTICS_WITHDRAWAL_SUCCESS = 'FETCH_CUSTOMER_MACHINES_STATISTICS_WITHDRAWAL_SUCCESS',
  FETCH_CUSTOMER_MACHINES_STATISTICS_WITHDRAWAL_FAIL = 'FETCH_CUSTOMER_MACHINES_STATISTICS_WITHDRAWAL_FAIL',

  FETCH_CUSTOMER_MACHINE_STATISTICS_WITHDRAWAL = 'FETCH_CUSTOMER_MACHINE_STATISTICS_WITHDRAWAL',
  FETCH_CUSTOMER_MACHINE_STATISTICS_WITHDRAWAL_SUCCESS = 'FETCH_CUSTOMER_MACHINE_STATISTICS_WITHDRAWAL_SUCCESS',
  FETCH_CUSTOMER_MACHINE_STATISTICS_WITHDRAWAL_FAIL = 'FETCH_CUSTOMER_MACHINE_STATISTICS_WITHDRAWAL_FAIL',

  CREATE_CUSTOMER_MACHINE = 'CREATE_CUSTOMER_MACHINE',
  CREATE_CUSTOMER_MACHINE_SUCCESS = 'CREATE_CUSTOMER_MACHINE_SUCCESS',
  CREATE_CUSTOMER_MACHINE_FAIL = 'CREATE_CUSTOMER_MACHINE_FAIL'
}

interface MachineActionType<T, P> {
  type: T;
  payload: P;
  asyncDispatch?: any;
}

export type FetchSuccessMachinesPayload = Machine[];
export type MachinesStatisticsWithdrawalsSeries = StatisticTimeSeriesData | null;
export type MachineStatisticsWithdrawalsSeries = StatisticTimeSeriesData & { machineId: string };

export type MachineStatisticsWithdrawalsData = {
  [machineId: string]: StatisticTimeSeriesData;
}

export type CreateMachineDto = {
  name: string;
  specification: string;
}

export type CreateMachineResponse = { ok: boolean; }

export type MachineAction =
  | MachineActionType<
    typeof MachineActions.FETCH_CUSTOMER_MACHINES,
    RequestPayload
  >
  | MachineActionType<
    typeof MachineActions.FETCH_CUSTOMER_MACHINES_SUCCESS,
    ResponsePayload<FetchSuccessMachinesPayload>
  >
  | MachineActionType<
    typeof MachineActions.FETCH_CUSTOMER_MACHINES_STATISTICS_WITHDRAWAL,
    RequestPayload
  >
  | MachineActionType<
    typeof MachineActions.FETCH_CUSTOMER_MACHINES_STATISTICS_WITHDRAWAL_SUCCESS,
    ResponsePayload<MachinesStatisticsWithdrawalsSeries>
  >
  | MachineActionType<
    typeof MachineActions.FETCH_CUSTOMER_MACHINE_STATISTICS_WITHDRAWAL,
    RequestPayload
  >
  | MachineActionType<
    typeof MachineActions.FETCH_CUSTOMER_MACHINE_STATISTICS_WITHDRAWAL_SUCCESS,
    ResponsePayload<MachineStatisticsWithdrawalsSeries>
  >
  | MachineActionType<
    typeof MachineActions.CREATE_CUSTOMER_MACHINE,
    RequestPayload
  >
  | MachineActionType<
    typeof MachineActions.CREATE_CUSTOMER_MACHINE_SUCCESS,
    ResponsePayload<CreateMachineResponse>
  >