import * as StockArticleActions from '../../../../actions/customer-article';
import { ArticleCard } from '../articleCard';
import { ArticleEventCard } from '../articleEventCard';
import { ArticleForm } from '../articleForm';
import { ArticleOrderForm } from '../articleOrderForm';
import { CreateArticleForm } from '../createArticle';
import { CustomerRouteParams } from '../../../../pages/customer/models';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  OrderStockArticle,
  StockArticle,
  StockArticleActions as StockArticleActionsModel,
  UpsertStockArticle
} from '../../../../model/customer-article';
import { RootState } from '../../../../reducers';
import { WikusButton } from '@wikus-ui/components/core/button';
import { WikusConfirmation } from '@wikus-ui/components/core/confirmation';
import { WikusHr } from '@wikus-ui/components/core/hr';
import { WikusItem, WikusSection } from '@wikus-ui/components/core/spacing';
import { WikusPanel } from '@wikus-ui/components/core/card';
import { useActions } from '../../../../actions';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const CustomerStorageAdmin = () => {
  const intl = useIntl();
  const { id: customerId } = useParams<CustomerRouteParams>();
  const customerArticleActions: typeof StockArticleActions =
    useActions(StockArticleActions);
  const customerArticles = useSelector((state: RootState) => state.customerArticles);
  const [deleteId, setDeleteId] = useState<number>();

  const [articleBeingEdited, setArticleBeingEdited] = useState<StockArticle | null>(
    null
  );

  const sortedStockArticles = customerArticles?.sort((a, b) =>
    a.id > b.id ? -1 : 1
  );

  useEffect(() => {
    if (customerId) {
      customerArticleActions.fetchStockArticles(customerId);
    }
  }, []);

  const onSubmit = async (article: UpsertStockArticle) => {
    const resp = await customerArticleActions.updateStockArticle(article);
    const isSuccess =
      resp.type === StockArticleActionsModel.UPDATE_CUSTOMER_ARTICLE_SUCCESS;

    if (isSuccess) {
      console.log('updated', article);
    }

    return isSuccess;
  };

  const onOrder = async (article: OrderStockArticle) => {
    const resp = await customerArticleActions.orderStockArticle(article);
    const isSuccess =
      resp.type === StockArticleActionsModel.ORDER_CUSTOMER_ARTICLE_SUCCESS;

    if (isSuccess) {
      console.log('updated', article);
    }

    return isSuccess;
  };

  const onEdit = async (article: StockArticle) => {
    const resp = await customerArticleActions.fetchStockArticleItems(article.id);
    const isSuccess =
      resp.type === StockArticleActionsModel.FETCH_CUSTOMER_ARTICLE_ITEMS_SUCCESS;

    if (isSuccess) {
      setArticleBeingEdited(article);
    }
  }

  const _onDelete = async () => {
    if (!deleteId) {
      return;
    }

    const { type } = await customerArticleActions.deleteStockArticle(deleteId);
    setDeleteId(undefined);
    return type === StockArticleActionsModel.DELETE_CUSTOMER_ARTICLE_SUCCESS;
  }

  const renderEvents = (article: StockArticle) => () => {
    return article.events?.map(event => <ArticleEventCard event={event} />);
  }

  return (
    <>
      <CreateArticleForm />
      <WikusSection spacingDirection="up">
        <WikusPanel
          title={intl.formatMessage({ id: 'customers.tabs.storage' })}
        >
          {!sortedStockArticles?.length
            ? (
              <WikusPanel elevation={0} style={{ textAlign: 'center' }}>
                <FormattedMessage id="nothing-found" />
              </WikusPanel>
            )
            : undefined
          }
          {sortedStockArticles?.map((article, index) => (
            <WikusItem spacingDirection="down" key={article.id}>
              {index > 0 ? <WikusHr color="grey" /> : null}
              <ArticleCard
                article={article}
                isExpanded={articleBeingEdited?.id === article.id}
                onClose={() => setArticleBeingEdited(null)}
                buttons={
                  articleBeingEdited?.id !== article.id
                    ? [
                      <WikusButton
                        color="primary"
                        variant="outlined"
                        onClick={() => setDeleteId(article.id)}
                      >
                        {intl.formatMessage({ id: 'delete' })}
                      </WikusButton>,
                      <WikusButton
                        color="primary"
                        onClick={() => onEdit(article)}
                      >
                        {intl.formatMessage({ id: 'edit' })}
                      </WikusButton>
                    ]
                    : []
                }
              >
                <ArticleForm
                  article={article}
                  onSubmit={onSubmit}
                />
                <WikusItem spacingDirection="vertical">
                  <WikusHr color="grey" />
                </WikusItem>
                <ArticleOrderForm
                  article={article}
                  onSubmit={onOrder} />
              </ArticleCard>
            </WikusItem>
          ))}
        </WikusPanel>
      </WikusSection>

      <WikusConfirmation
        title={intl.formatMessage({ id: 'customers.tabs.storage.confirmation.delete' })}
        open={!!deleteId}
        onAbort={() => setDeleteId(undefined)}
        onConfirm={_onDelete}
        message="customers.tabs.storage.delete">
        <FormattedMessage id="customers.tabs.storage.confirmation.delete.question" />
      </WikusConfirmation>
    </>
  );
};
