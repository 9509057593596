import * as CustomerStatisticsActions from '../../../../actions/customer-statistics';
import * as StockArticleActions from '../../../../actions/customer-article';
import { ArticleEventCard } from '../articleEventCard';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { RootState } from '../../../../reducers';
import { StockArticleEvent } from '../../../../model';
import { WikusButton } from '@wikus-ui/components/core/button';
import { WikusConfirmation } from '@wikus-ui/components/core/confirmation';
import { useActions } from '../../../../actions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const CustomerStatisticsOrders = ({ customerId, articleIds }: any) => {
  const intl = useIntl();
  const statistics = useSelector((state: RootState) => state.customerStatistics);
  const customerStatisticsActions: typeof CustomerStatisticsActions = useActions(CustomerStatisticsActions);
  const customerArticleActions: typeof StockArticleActions = useActions(StockArticleActions);
  const [confirmOrder, setConfirmOrder] = useState<StockArticleEvent>();

  useEffect(() => {
    customerStatisticsActions.fetchStatisticsOrders({ customerId, articleIds });
  }, [articleIds, customerId]);

  const onFulfill = async (order: StockArticleEvent) => {
    const resp = await customerStatisticsActions.fulfillStatisticsOrder({
      customerId,
      orderId: order.id,
    });
    setConfirmOrder(undefined);
    return resp;
  }

  return (
    <>
      <Grid container spacing={2}>
        {statistics?.orders?.map((o) => (<Grid item xs>
          <ArticleEventCard event={o} showArticleId>
            <WikusButton
              onClick={() => setConfirmOrder(o)}
              variant='outlined'
              color='primary'>
              {intl.formatMessage({
                id: 'customers.orders.fulfill',
              })}
            </WikusButton>
          </ArticleEventCard>
        </Grid>))}
      </Grid>
      <WikusConfirmation
        open={!!confirmOrder}
        title={intl.formatMessage({
          id: 'customers.orders.fulfill.title',
        })}
        onConfirm={() => onFulfill(confirmOrder!)}
        onAbort={() => setConfirmOrder(undefined)}
      >
        <FormattedMessage id="customers.orders.fulfill.message" />
      </WikusConfirmation>
    </>
  );
}