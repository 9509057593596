import { Chart as ChartJS, ChartOptions, ChartType } from 'chart.js';
import { StatisticTimeSeries, StatisticTimeSeriesData } from '../model/statistics';
import { getElementAtEvent } from 'react-chartjs-2';
import { map } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

export type UseChartProps = {
  key: string;
  data?: StatisticTimeSeriesData | null;
  onPointClicked?: (point: { x: Date, y: number }) => void;
}

type ChartTypeSubset = Extract<ChartType, 'line' | 'bar'>;
export const useChart = <T extends ChartTypeSubset>({
  key,
  data: chartData,
  onPointClicked,
}: UseChartProps) => {
  const intl = useIntl();

  const baseOptions: ChartOptions<ChartTypeSubset> = {
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      x: {
        type: 'time',
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const getBaseChartOptions = (key: string) => ({
    ...baseOptions,
    plugins: {
      ...baseOptions.plugins,
      title: {
        display: true,
        text: intl.formatMessage({ id: key }),
      },
    },
  });

  const createDataset = (series: StatisticTimeSeries) => ({
    label: series.label,
    data: map(series.data, (data) => ({
      x: +(new Date(data.x)),
      y: data.y,
    })),
    datalabels: {
      formatter: (value: any) => value?.y || '-',
    },
  });

  const { options, data } = useMemo(() => ({
    options: {
      ...getBaseChartOptions(key),
      scales: {
        ...getBaseChartOptions(key).scales,
        x: {
          ...(getBaseChartOptions(key).scales!.x as any),
          min: chartData?.config.minDate,
          max: chartData?.config.maxDate,
          time: {
            displayFormats: {
              quarter: chartData?.config.isZoomedIn ? 'DD MMM' : 'MMM YYYY',
            },
            unitStepSize: 1,
            unit: chartData?.config.unit,
          },
        },
      },
    },
    data: {
      datasets: map(chartData?.series, createDataset),
    },
  }), [chartData, key]);

  const ref = useRef<ChartJS<T>>(null);

  const onClickHandler = useCallback(() => (event: any) => {
    // Ignore click if already zoomed in
    if (!chartData || chartData?.config.isZoomedIn || !onPointClicked) {
      return;
    }
    const { current: chart } = ref;

    if (!chart) {
      return;
    }

    const el = getElementAtEvent(chart as ChartJS<ChartTypeSubset>, event)[0];

    if (!el) {
      return;
    }

    const { index, datasetIndex } = el;
    const { x, y } = chart.data.datasets[datasetIndex].data[index] as any;
    const date = new Date(x);

    onPointClicked({
      x: date,
      y,
    });
  }, [chartData, ref]);

  return { options, data, ref, onClickHandler };
}