import { FetchSuccessMachinesPayload, Machine, MachineAction, MachineActions, MachineStatisticsWithdrawalsData, MachineStatisticsWithdrawalsSeries, MachinesStatisticsWithdrawalsSeries } from '../model/customer-machine';
import { ResponsePayload } from '../model/request';
import createReducer from './createReducer';

export const customerMachines = createReducer<Machine[]>([], {
  [MachineActions.FETCH_CUSTOMER_MACHINES_SUCCESS](
    state: Machine[],
    action: MachineAction
  ) {
    return (action.payload as ResponsePayload<FetchSuccessMachinesPayload>).data;
  },
});

export const customerMachinesStatisticsWithdrawals = createReducer<MachinesStatisticsWithdrawalsSeries>(null, {
  [MachineActions.FETCH_CUSTOMER_MACHINES_STATISTICS_WITHDRAWAL_SUCCESS](
    state: MachinesStatisticsWithdrawalsSeries,
    action: MachineAction
  ) {
    return (action.payload as ResponsePayload<MachinesStatisticsWithdrawalsSeries>).data;
  },
});

export const customerMachineStatisticsWithdrawals = createReducer<MachineStatisticsWithdrawalsData>({}, {
  [MachineActions.FETCH_CUSTOMER_MACHINE_STATISTICS_WITHDRAWAL_SUCCESS](
    state: MachineStatisticsWithdrawalsSeries,
    action: MachineAction
  ) {
    const { machineId, ...data } = (action.payload as ResponsePayload<MachineStatisticsWithdrawalsSeries>).data;
    return {
      ...state,
      [machineId]: data,
    }
  },
});