import 'chartjs-adapter-moment';
import * as CustomerStatisticsActions from '../../../../actions/customer-statistics';
import * as StockArticleActions from '../../../../actions/customer-article';
import { Bar, Line, getElementAtEvent } from 'react-chartjs-2';
import { Box, Button } from '@material-ui/core';
import { ChartData, Chart as ChartJS, ChartOptions } from 'chart.js';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { RootState } from '../../../../reducers';
import { filter, map } from 'lodash';
import { useActions } from '../../../../actions';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export const CustomerStatisticsGraph = ({ customerId, articleIds, months }: any) => {
  const intl = useIntl();
  const statistics = useSelector((state: RootState) => state.customerStatistics);
  const customerStatisticsActions: typeof CustomerStatisticsActions = useActions(CustomerStatisticsActions);
  const articles = useSelector((state: RootState) => state.customerArticles);
  const customerArticleActions: typeof StockArticleActions =
    useActions(StockArticleActions);
  const baseOptions: ChartOptions<'line' | 'bar'> = {
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      x: {
        type: 'time',
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  const [withdrawChartOptions, setWithdrawChartOptions] = useState<ChartOptions<'bar'>>({
    ...baseOptions,
    plugins: {
      ...baseOptions.plugins,
      title: {
        display: true,
        text: intl.formatMessage({ id: 'statistics.graph.withdrawalsLabel' }),
      },
    },
  });
  const [confirmedChartOptions, setConfirmedChartOptions] = useState<ChartOptions<'bar'>>({
    ...baseOptions,
    plugins: {
      ...baseOptions.plugins,
      title: {
        display: true,
        text: intl.formatMessage({ id: 'statistics.graph.confirmedLabel' }),
      },
    },
  });
  const [stockChartOptions, setStockChartOptions] = useState<ChartOptions<'line'>>({
    ...baseOptions,
    plugins: {
      ...baseOptions.plugins,
      title: {
        display: true,
        text: intl.formatMessage({ id: 'statistics.graph.stockLabel' }),
      },
    },
  });
  const [stockChartData, setStockChartData] = useState<ChartData<'line'>>({
    datasets: [],
  });
  const [withdrawChartData, setWithdrawChartData] = useState<ChartData<'bar'>>({
    datasets: [],
  });
  const [confirmedChartData, setConfirmedChartData] = useState<ChartData<'bar'>>({
    datasets: [],
  });

  useEffect(() => {
    if (!statistics) {
      return;
    }

    const { stock, minDate, maxDate, isZoomedIn } = statistics;
    const unit = isZoomedIn || months === 1 ? 'day' : 'month';
    const displayFormats = isZoomedIn || months === 1 ? 'DD MMM' : 'MMM YYYY';
    if (minDate && maxDate) {
      setStockChartOptions({
        ...stockChartOptions,
        scales: {
          ...stockChartOptions.scales,
          x: {
            ...(stockChartOptions.scales!.x as any),
            min: minDate,
            max: maxDate,
            time: {
              displayFormats: {
                quarter: displayFormats,
              },
              unitStepSize: 1,
              unit,
            },
          },
        },
      });
      setWithdrawChartOptions({
        ...withdrawChartOptions,
        scales: {
          ...withdrawChartOptions.scales,
          x: {
            ...(withdrawChartOptions.scales!.x as any),
            min: minDate,
            max: maxDate,
            time: {
              displayFormats: {
                quarter: displayFormats,
              },
              unitStepSize: 1,
              unit,
            },
          },
        },
      });
      setConfirmedChartOptions({
        ...confirmedChartOptions,
        scales: {
          ...confirmedChartOptions.scales,
          x: {
            ...(confirmedChartOptions.scales!.x as any),
            min: minDate,
            max: maxDate,
            time: {
              displayFormats: {
                quarter: displayFormats,
              },
              unitStepSize: 1,
              unit,
            },
          },
        },
      });
    }

    const stockDatasets = map(
      Object.entries(stock),
      (([articleId, data]) => createDataset(
        data.map(a => ({ x: +(new Date(a.date)), y: a.total })),
        // data.map(a => a.total),
        articleId
      ))
    );
    const withdrawDatasets = map(
      Object.entries(stock),
      (([articleId, data]) => createDataset(
        filter(data, a => !!a.withdrawals).map(a => ({ x: +(new Date(a.date)), y: a.withdrawals })),
        articleId
      ))
    );
    const confirmedDatasets = map(
      Object.entries(stock),
      (([articleId, data]) => createDataset(
        filter(data, a => !!a.confirmed).map(a => ({ x: +(new Date(a.date)), y: a.confirmed })),
        articleId
      ))
    );
    setStockChartData({
      datasets: stockDatasets,
    });
    setWithdrawChartData({
      datasets: withdrawDatasets,
    });
    setConfirmedChartData({
      datasets: confirmedDatasets,
    });
  }, [statistics])

  const fetchDefault = () => {
    customerStatisticsActions.fetchStatisticsStock({ customerId, articleIds, unitCount: months });
  }

  useEffect(() => {
    customerArticleActions.fetchStockArticles(customerId);
    fetchDefault();
  }, [articleIds, customerId, months]);

  const createDataset = (data: any[], articleId: string) => ({
    label:
      articleId === 'all' ?
        intl.formatMessage({ id: articleId }) :
        articles.find((a: any) => a.id === parseInt(articleId))?.article.itemNumber,
    data,
    datalabels: {
      formatter: (value: any) => value?.y || '-',
    },
  });

  const stockRef = useRef<ChartJS<'line'>>(null);
  const withdrawRef = useRef<ChartJS<'bar'>>(null);
  const confirmedRef = useRef<ChartJS<'bar'>>(null);
  const dataPointOnClick = useCallback((chartRef: RefObject<ChartJS>) => (event: any) => {
    // Ignore click if already zoomed in
    if (statistics?.isZoomedIn) {
      return;
    }
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    const el = getElementAtEvent(chart, event)[0];

    if (!el) {
      return;
    }

    const { index, datasetIndex } = el;
    const { x } = chart.data.datasets[datasetIndex].data[index] as any;
    const date = new Date(x);

    customerStatisticsActions.fetchStatisticsStock({
      customerId,
      articleIds,
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    });
  }, [statistics]);

  const backOnClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    fetchDefault();
  };

  return (
    <>
      <Line
        ref={stockRef}
        data={stockChartData}
        options={stockChartOptions}
        onClick={dataPointOnClick(stockRef)}
      />
      <Box mt={2}>
        <Bar
          ref={withdrawRef}
          data={withdrawChartData}
          options={withdrawChartOptions}
          onClick={dataPointOnClick(withdrawRef)}
        />
      </Box>
      <Box mt={2}>
        <Bar
          ref={confirmedRef}
          data={confirmedChartData}
          options={confirmedChartOptions}
          onClick={dataPointOnClick(confirmedRef)}
        />
      </Box>
      <Box mt={2}>
        <Button disabled={!statistics?.isZoomedIn} onClick={backOnClick} variant="contained">
          {intl.formatMessage({ id: 'statistics.graph.resetDate' })}
        </Button>
      </Box>
    </>
  );
};
