import * as MachineActions from '../../../../actions/customer-machine';
import { Box, Button } from '@material-ui/core';
import { CustomerRouteParams } from 'src/pages/customer/models';
import { Line } from 'react-chartjs-2';
import { RootState } from 'src/reducers';
import { Skeleton } from '@material-ui/lab';
import { useActions } from '../../../../actions';
import { useChart } from '../../../../hooks/use-base-chart';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export type CustomerMachinesStatisticsWithdrawalsProps = {
  machineIds?: string[];
  months?: number;
}

export const CustomerMachinesStatisticsWithdrawals = ({ machineIds, months }: CustomerMachinesStatisticsWithdrawalsProps) => {
  const intl = useIntl();
  const { id: customerId } = useParams<CustomerRouteParams>();
  const machineStatisticsWithdrawals = useSelector((state: RootState) => state.customerMachinesStatisticsWithdrawals);
  const { options, data, ref, onClickHandler } = useChart<'line'>({
    key: 'machines.statistics.withdrawals',
    data: machineStatisticsWithdrawals,
    onPointClicked({ x: date }) {
      customerMachineActions.fetchMachinesStatisticsWithdrawals(
        customerId,
        {
          unitCount: months || 6,
          year: date.getFullYear(),
          month: date.getMonth() + 1,
        },
        machineIds
      );
    },
  });
  const customerMachineActions: typeof MachineActions =
    useActions(MachineActions);

  const fetchDefault = () => {
    customerMachineActions.fetchMachinesStatisticsWithdrawals(
      customerId,
      {
        unitCount: months || 6,
      },
      machineIds
    );
  }

  useEffect(() => {
    if (customerId) {
      fetchDefault();
    }
  }, [machineIds, months]);

  const backOnClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    fetchDefault();
  };

  if (!machineStatisticsWithdrawals) {
    return (
      <Skeleton variant="rect" height={48} />
    )
  }

  return (
    <>
      <Line
        ref={ref}
        data={data}
        options={options}
        onClick={onClickHandler()}
      />
      <Box mt={2}>
        <Button disabled={!machineStatisticsWithdrawals?.config.isZoomedIn} onClick={backOnClick} variant="contained">
          {intl.formatMessage({ id: 'statistics.graph.resetDate' })}
        </Button>
      </Box>
    </>
  )
}