import * as MachineActions from '../../../../actions/customer-machine';
import { Box, Button } from '@material-ui/core';
import { CustomerRouteParams } from 'src/pages/customer/models';
import { Line } from 'react-chartjs-2';
import { RootState } from 'src/reducers';
import { Skeleton } from '@material-ui/lab';
import { useActions } from '../../../../actions';
import { useChart } from '../../../../hooks/use-base-chart';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export type CustomerMachineStatisticsWithdrawalsProps = {
  machineId: string;
  months?: number;
}

export const CustomerMachineStatisticsWithdrawals = ({ machineId, months }: CustomerMachineStatisticsWithdrawalsProps) => {
  const intl = useIntl();
  const { id: customerId } = useParams<CustomerRouteParams>();
  const machineStatisticsWithdrawals = useSelector((state: RootState) => state.customerMachineStatisticsWithdrawals);
  const machineStatisticsData = machineStatisticsWithdrawals?.[machineId];
  const { options, data, ref, onClickHandler } = useChart<'line'>({
    key: 'machine.statistics.withdrawals',
    data: machineStatisticsData,
    onPointClicked({ x: date }) {
      customerMachineActions.fetchMachineStatisticsWithdrawals(
        customerId,
        machineId,
        {
          unitCount: months || 6,
          year: date.getFullYear(),
          month: date.getMonth() + 1,
        }
      );
    },
  });
  const customerMachineActions: typeof MachineActions =
    useActions(MachineActions);

  const fetchDefault = () => {
    customerMachineActions.fetchMachineStatisticsWithdrawals(
      customerId,
      machineId,
      {
        unitCount: months || 6,
      }
    );
  }

  useEffect(() => {
    if (customerId) {
      fetchDefault();
    }
  }, [machineId, months]);

  const backOnClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    fetchDefault();
  };

  if (!machineStatisticsData) {
    return (
      <Skeleton variant="rect" height={48} />
    )
  }

  return (
    <>
      <Line
        ref={ref}
        data={data}
        options={options}
        onClick={onClickHandler()}
      />
      <Box mt={2}>
        <Button disabled={!machineStatisticsData?.config.isZoomedIn} onClick={backOnClick} variant="contained">
          {intl.formatMessage({ id: 'statistics.graph.resetDate' })}
        </Button>
      </Box>
    </>
  )
}