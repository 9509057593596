import { OnSubmitHandler, useInputGroup } from '@wikus-ui/hooks/useInputGroup';
import { WikusButton, WikusButtonRow } from '@wikus-ui/components/core/button';
import { WikusInputRow } from '@wikus-ui/components/core/input';
import { useIntl } from 'react-intl';

import * as CustomerUserActions from './../../../actions/customer-user';
import * as InfoActions from './../../../actions/info';

import {
  CustomerUser,
  CustomerUserActions as CustomerUserActionsModel
} from './../../../model/customer-user';
import { RootState } from './../../../reducers';
import { useActions } from './../../../actions';

import { WikusSelect } from '@wikus-ui/components/core/select';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

type TEditCustomerUserFormProps = {
  onEdit?: () => void;
  onAbort?: () => void;
  customerUser: CustomerUser | null;
  showForm: boolean;
};

export const EditCustomerUserForm = ({
  onEdit,
  onAbort,
  customerUser,
  showForm,
}: TEditCustomerUserFormProps) => {
  const intl = useIntl();
  const EditCustomerUserInputGroup = 'edit-user';

  const customerUserActions: typeof CustomerUserActions =
    useActions(CustomerUserActions);
  const infoActions: typeof InfoActions = useActions(InfoActions);

  const info = useSelector((state: RootState) => state.info);

  const _onSubmit: OnSubmitHandler = async () => {
    if (!isValid) {
      return;
    }

    const { type } = await customerUserActions.updateCustomerUser(
      value,
      // we can only see the form if there is a user
      customerUser!.id
    );

    const isSuccess =
      type === CustomerUserActionsModel.UPDATE_CUSTOMER_USER_SUCCESS;

    if (isSuccess) {
      onEdit && onEdit();
    }

    return isSuccess;
  };

  const {
    value,
    setGroup,
    isValid,
    onSubmit,
    createInputProps,
    createSwitchProps,
    isSubmitting,
  } = useInputGroup(EditCustomerUserInputGroup, _onSubmit);

  useEffect(() => {
    setGroup({
      value: {
        country: customerUser?.country,
        language: customerUser?.language,
        orderMail: customerUser?.orderMail,
        sendToCustomer: customerUser?.sendToCustomer,
        featureAutoCreateArticles: customerUser?.featureAutoCreateArticles,
        featureAutoConfirmArticles: customerUser?.featureAutoConfirmArticles,
        featureMachines: customerUser?.featureMachines,
      },
    });
  }, [customerUser]);

  useEffect(() => {
    infoActions.fetchInfo();
  }, []);

  if (!showForm || !info) return null;

  return (
    <form onSubmit={onSubmit}>
      <WikusInputRow>
        <WikusSelect
          xs={6}
          {...createInputProps('country')}
          options={Object.keys(info.countries).map((key) => ({
            id: key,
            name: info?.countries[key],
          }))}
        />
        <WikusSelect
          xs={6}
          {...createInputProps('language')}
          options={Object.keys(info.languages).map((key) => ({
            id: key,
            name: info?.languages[key],
          }))}
        />
      </WikusInputRow>
      <WikusButtonRow>
        <WikusButton type="submit" color="primary" disabled={isSubmitting || isValid === undefined}>
          {intl.formatMessage({
            id: 'create-user.submit',
          })}
        </WikusButton>
        <WikusButton
          onClick={onAbort}
          color="primary"
          variant="outlined"
          disabled={isSubmitting}
        >
          {intl.formatMessage({
            id: 'create-user.abort',
          })}
        </WikusButton>
      </WikusButtonRow>
    </form>
  );
};
