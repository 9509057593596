import { FormattedMessage, useIntl } from 'react-intl';
import { WikusPanel } from '@wikus-ui/components/core/card';

import * as MachineActions from '../../../actions/customer-machine';
import {
  CreateMachineDto,
  MachineActions as MachineActionsModel
} from '../../../model/customer-machine';
import { useActions } from '../../../actions';

import { CustomerRouteParams } from '../../../pages/customer/models';
import { MachineForm } from './machine-form';
import { WikusConfirmation } from '@wikus-ui/components/core/confirmation';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

type TCreateMachineFormProps = {
  onCreate?: () => void;
};

export const CreateMachineForm = ({
  onCreate,
}: TCreateMachineFormProps) => {
  const { id: customerId } = useParams<CustomerRouteParams>();

  const intl = useIntl();
  const [createMachine, setCreateMachine] = useState<{ resolve: (value: boolean) => void }>();
  const customerMachineActions: typeof MachineActions = useActions(
    MachineActions
  );

  const onSubmit = async (machine: CreateMachineDto) => {
    const canContinue = await new Promise<boolean>((resolve) => {
      setCreateMachine({ resolve });
    });
    setCreateMachine(undefined);

    if (!canContinue) {
      return;
    }

    const resp = await customerMachineActions.createMachine(customerId, machine);
    const isSuccess =
      resp.type === MachineActionsModel.CREATE_CUSTOMER_MACHINE_SUCCESS;

    if (isSuccess) {
      onCreate && onCreate();
    }

    return isSuccess;
  };

  return (
    <>
      <WikusPanel
        title={intl.formatMessage({ id: 'customer-machine.create' })}
      >
        <MachineForm onSubmit={onSubmit} />
      </WikusPanel>

      <WikusConfirmation
        title={intl.formatMessage({ id: 'customers.tabs.machine.confirmation.create' })}
        open={createMachine !== undefined}
        onAbort={() => createMachine?.resolve(false)}
        onConfirm={() => createMachine?.resolve(true)}>
        <FormattedMessage id="customers.tabs.machine.confirmation.create.question" />
      </WikusConfirmation>
    </>
  );
};
